export const ErrorMessages = {
  FORBIDDEN_403_TITLE: "Forbidden",
  FORBIDDEN_403_MESSAGE: "Access Denied You don’t have permission to access",
  NOTFOUND_404_TITLE: "Page Not Found",
  NOTFOUND_404_MESSAGE: "We couldn’t find this page",
  NOTFOUND_DATA: "Not found data",
  SMT_WRONG: "Something must have gone wrong.",
  SORRY_ERROR: "We’re really sorry!",
  SMT_WRONG_ERROR: "Something must have gone wrong. Please reload this page and try again!",
  REQUIRE_INFORMATION: "Required information",
  REPORT_NAME_REGEX: "Report name must be letters, numbers, space and ( ) _ - , .",
  REPORT_NAME_MAX_LENGTH: "Report name max length is 100 characters",
  REPORT_NAME_VAT_MAX_LENGTH: "Report name max length is 50 characters",
  SUPPORT_FORM_CONTACT_MAX_LENGTH: "Contact name max length is 255 characters",
  SUPPORT_FORM_CONTACT_REGEX: "Contact name must be letters or space.",
  SUPPORT_FORM_MESSAGE_MAX_LENGTH: "Message max length is 5000 characters",
  SCHEMES_INFORMATION_REQUIRED: "Schemes information is required.",  
  ST_WRONG_AND_RELOAD_TITLE: "Something went wrong!",
  ST_WRONG_AND_RELOAD_MESSAGE: "There have been some issues with retrieving and processing the data. Please reload the page and try again."
};

export const PasswordMessages = {
  PASS_MAX_LENGTH: "Password max length is 100 characters",
  PASS_NOT_STRONG: "New password is not strong enough. Please follow guidelines above",
  PASS_NOT_MATCH: "Passwords do not match",
  PASS_SPACE: "New password can't start or end with a blank space",
  PASS_SAME_CURRENT: "New password must be different from the current one. Please choose another",
  PASS_UPDATE_TITLE_SUCESS: "Password Update Complete!",
  PASS_UPDATE_CONTENT_SUCESS: "Congratulations! Your password has been updated successfully.",
  OlD_PASS_INCORRECT: "Old password is incorrect.",
};

export const SuccessMessages = {
  MARKETING_PREFERENCES_SUCCESS_TITLE: "Marketing Preferences Update Complete!",
  MARKETING_PREFERENCES_SUCCESS_CONTENT:
    "Congratulations! Your marketing preference has been updated successfully.",
};

export const UpdatePicSuccessMessages = {
  UPDATE_PIC_SUCCESS_TITLE: "Upload Profile Picture",
  UPDATE_PIC_SUCCESS_CONTENT:
    "Congratulations! Your new profile picture has been uploaded successfully.",
};

export const PGAFormResetMessages = {
  PGA_FORM_RESET_TITLE: "Information",
  PGA_FORM_RESET_CONTENT:
    "This PGA Form has just been reset by ABP LiveStock Team. Please refresh the page to get the latest data.",
  PGA_FORM_RESET_OK_TEXT: "Refresh",
};

export const FarmGroupStatusMessages = {
  TITLE: "Information",
  CONTENT_LIST:
    "Sorry, the list has been updated. Please refresh the page to get the latest data.",
  CONTENT_DETAIL:
    "Sorry, this group is no longer available. Please refresh the page to get the latest data.",
};

export const MyDocumentStatusMessages = {
  MY_DOCUMENT_UPLOAD_SUCCESS: "Your document has been successfully uploaded.",
  MY_DOCUMENT_UPDATE_SUCCESS: "Your document has been successfully updated.",
  MY_DOCUMENT_UPLOAD_FAILED: "There have been some issues with uploading your document. Please reload the page and try again.",
  MY_DOCUMENT_UPDATE_FAILED: "There have been some issues with updating your document. Please reload the page and try again.",
  MY_DOCUMENT_STM_WRONG: "Something went wrong!",
  MY_DOCUMENT_FILE_SIZE: "File size must not exceed 10MB. Please check again.",
  MY_DOCUMENT_FILE_EXT: "File type must be: PNG, JPG, JPEG, PDF, XLSX, XLS, PPTX, PPT or CSV. Please check again.",
  MY_DOCUMENT_DELETE_DOCUMENT_ERR: "There have been some issues with deleting your document. Please reload the page and try again.",
};
